@font-face {
  font-family: 'safbilling';
  src: url('../font/safbilling.eot?62773706');
  src: url('../font/safbilling.eot?62773706#iefix') format('embedded-opentype'),
       url('../font/safbilling.woff2?62773706') format('woff2'),
       url('../font/safbilling.woff?62773706') format('woff'),
       url('../font/safbilling.ttf?62773706') format('truetype'),
       url('../font/safbilling.svg?62773706#safbilling') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'safbilling';
    src: url('../font/safbilling.svg?62773706#safbilling') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "safbilling";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-flow-split:before { content: '\e800'; } /* '' */
.icon-users-outline:before { content: '\e801'; } /* '' */
.icon-cancel-outline:before { content: '\e802'; } /* '' */
.icon-ok-outline:before { content: '\e803'; } /* '' */
.icon-pencil:before { content: '\e804'; } /* '' */
.icon-right:before { content: '\e805'; } /* '' */
.icon-left:before { content: '\e806'; } /* '' */
.icon-export-outline:before { content: '\e807'; } /* '' */
.icon-trash:before { content: '\e808'; } /* '' */
.icon-user-outline:before { content: '\e809'; } /* '' */
.icon-star:before { content: '\e80a'; } /* '' */
.icon-heart:before { content: '\e80b'; } /* '' */
.icon-search-outline:before { content: '\e80c'; } /* '' */
.icon-lock-open:before { content: '\e80d'; } /* '' */
.icon-lock:before { content: '\e80e'; } /* '' */
.icon-location-outline:before { content: '\e80f'; } /* '' */
.icon-wrench-outline:before { content: '\e810'; } /* '' */
.icon-cog-outline:before { content: '\e811'; } /* '' */
.icon-cloud:before { content: '\e812'; } /* '' */
.icon-eject-outline:before { content: '\e813'; } /* '' */
.icon-left-outline:before { content: '\e814'; } /* '' */
.icon-right-outline:before { content: '\e815'; } /* '' */
.icon-up-outline:before { content: '\e816'; } /* '' */
.icon-down-outline:before { content: '\e817'; } /* '' */
.icon-headphones:before { content: '\e818'; } /* '' */
.icon-block-outline:before { content: '\e819'; } /* '' */
.icon-popup:before { content: '\e81a'; } /* '' */
.icon-calendar-outlilne:before { content: '\e81b'; } /* '' */
.icon-map:before { content: '\e81c'; } /* '' */
.icon-reply-outline:before { content: '\e81d'; } /* '' */
.icon-landing:before { content: '\e81e'; } /* '' */
.icon-business:before { content: '\e81f'; } /* '' */
.icon-private:before { content: '\e820'; } /* '' */
.icon-attention:before { content: '\e821'; } /* '' */
.icon-attention-1:before { content: '\e822'; } /* '' */
.icon-reply:before { content: '\e823'; } /* '' */
.icon-retweet:before { content: '\e824'; } /* '' */
.icon-arrows-ccw:before { content: '\e825'; } /* '' */
.icon-loop:before { content: '\e826'; } /* '' */
.icon-arrows-cw:before { content: '\e827'; } /* '' */
.icon-cw:before { content: '\e828'; } /* '' */
.icon-cw-outline:before { content: '\e829'; } /* '' */
.icon-people:before { content: '\e82a'; } /* '' */
.icon-user:before { content: '\e82b'; } /* '' */
.icon-heart-circle:before { content: '\e82c'; } /* '' */
.icon-templates:before { content: '\e82d'; } /* '' */
.icon-social-network:before { content: '\e82e'; } /* '' */
.icon-school:before { content: '\e82f'; } /* '' */
.icon-external-link:before { content: '\e830'; } /* '' */
.icon-eye-off:before { content: '\e831'; } /* '' */
.icon-eye:before { content: '\e832'; } /* '' */
.icon-ok:before { content: '\e833'; } /* '' */
.icon-cancel:before { content: '\e834'; } /* '' */
.icon-government:before { content: '\e83a'; } /* '' */
.icon-ngo:before { content: '\e83b'; } /* '' */
